import React from 'react';  //crea interface
import ReactDOM from 'react-dom'; // crea interfacespara 
import './index.css';
import App from './App'; 
import 'bootstrap/dist/css/bootstrap.css'; //bootsrap
import * as serviceWorker from './serviceWorker'; //pwa app offline

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
); // para mostrar en el contenido en toor

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
