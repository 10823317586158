import React from 'react';
import './App.css';


function App() {
  return (

    <div className="App">
      <header>
        <nav className="navbar navbar-expand-lg d-flex fixed-top navbar-custom">
          <div className="container">
            <a className="navbar-brand" href="#">CFC Latam</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link" href="#socio">INICIO</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#conocimiento">CONOCIMIENTO</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#experiencia">EXPERIENCIA</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#nosotros">NOSOTROS</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contacto">CONTACTO</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
    
      </header>
      <div className="container mt-5">

        {/* UN SOCIO CONFIABLE */}
        <div className="row mt-5">
        <div id="socio" className="col-12 separator-distin">
        </div>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
          <img className='img-fluid' src="/images/imagen1.png" alt={"rent-icon"} />

          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 justify-content-center">
              <h3 className="mt-3 ">UN SOCIO CONFIABLE</h3>
              <p className="mt-5 text-diferent">CFC LATAM, son las iniciales de Crematorio Funeraria y Cementerio, cuyo objetivo es asesorar a empresas existentes o iniciándose en el rubro, que quieren desarrollar su negocio o solucionar problemas en el ámbito operacional.</p>
              <p className="mt-2 text-diferent">También a inversionistas que requieran una mirada del negocio, variables a considerar, indicadores de gestión y evaluación de proyectos.</p>
          </div>
        </div>
      {/* CONOCIMIENTO */}
        <div className="row mt-5">

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 bt-5">
              <div id="conocimiento" className="col-12 separator">                
              </div>

              <h3 className="text-center mt-2">CONOCIMIENTO</h3>
          </div> 
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
          <p className="text-left mt-2">Sus integrantes, tienen experiencia comprobada, en gerenciar operaciones en los cementerios Parque del Mar y Parque del Sendero, por sobre los 10 años administrando personal, resolviendo problemas, implementando mejoras, y desarrollando negocios complementarios como cremación, funerarias y florería; además construcciones adicionales. Por lo tanto, el conocimiento acumulado, nos permite tener una mirada global del negocio, en el ámbito operativo, comercial y administrativo, como a su vez la implementación.</p>

          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-3">
          <img className='img-card' src="/images/imagen2.png" alt={"rent-icon"} />
          </div>
        </div>
        {/* EXPERIENCIA */}
        <div className="row my-5">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
            <div id="experiencia" className="col-12 separator">                
            </div>
            <h3 > EXPERIENCIA</h3>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mr-0 mb-2 mx-auto">
          <img className='img-fluid pt-5' src="/images/imagen3.png" alt={"rent-icon"} />
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mt-2">
            <p className="text-left">Nuestro campo de acción es variado y tenemos experiencia, entre ellos:</p>
            <ul className="text-left">
              <li>Instalación de horno crematorio</li>
              <li>Mantenimiento de hornos crematorios</li>
              <li>Equipamiento de sala de cremación</li>
              <li>Mantenimiento de mesas de sepultación</li>
              <li>Cuidado del césped al tránsito de maquinaria pesada</li>
              <li>Criptas de hormigón</li>
              <li>Manuales de procedimientos para crematorios–funerarias–cementerios</li>
              <li>Capacitación al personal en crematorios – cementerios</li>
              <li>Estudios de mercado del rubro</li>
              <li>Estudio de capacidades y proyecciones</li>
              <li>Orientar en los permisos necesarios acorde al tipo de negocio a realizar</li>
            </ul>
          </div>
        </div>
        {/* NOSOTROS */}
        <div className="row mt-3">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
          <div id="nosotros" className="col-12 separator">                
              </div>
            <h3 className="mb-2">NOSOTROS</h3>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <p className="text-justify"><strong>Eduardo Silva</strong> <br></br>
              Técnico Superior en Electrónica Industrial, con 20 años en Organización Parque del Mar, con dos cementerios; Parque del Mar y Parque del Puerto, funeraria y crematorio de restos humanos. Experiencia en dirección del personal de terreno y de toda la maquinaria de cementerios y programación de actividades de funeraria.</p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <p className="text-justify"><strong>Luis Felipe Baeza</strong>  <br></br>
              Ingeniero Mecánico, con 28 años como Gerente de Operaciones en Organización Parque del Mar, con experiencia en: a) Creación del cementerio Parque del Puerto, b) creación de funeraria en su operación y venta a futuro, c)  creación y puesta en marcha del primer horno incinerador y muchos desarrollos del cementerio mismo.
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <p className="text-justify"><strong>Javier Strauszer</strong> <br></br>
              Ingeniero Civil Mecánico, MBA-UC, formado en organización Sendero: a) Gerente General Anticipar (3 años), puesta en marcha de preventa de servicio funerario , construcción de casa funeraria; b) Gerente Operaciones Sendero (7 años), a cargo de 12 cementerios en Chile, optimización de los procesos, habilitar cementerios, construcciones; c) Subgerente de Desarrollo (2 años) a cargo de implementar florería, funeraria horno crematorio y   mejorar los procesos de incineración.
            </p>
          </div>
        </div>

        {/* IMAGES FOOTER */}
        <div className="row mt-5">
          <div className="col-12">
            <img className='img-fluid' src="/images/footer1.png" alt={"rent-icon"} />
            <img className='img-fluid' src="/images/footer2.png" alt={"rent-icon"} />
            <img className='img-fluid' src="/images/footer3.png" alt={"rent-icon"} />
          </div>
        </div>
        
        {/* CONTACTO */}
        <div className="row text-center mt-5">
          
          <div className="col-12">
          <div id="contacto" className="col-12 separator">                
              </div>
              <h3 className="mb-2">CONTACTO</h3>
          </div>
          <div className="col-12">
            <p>Javier Strauszer<br></br>
              +569 94411576<br></br>
              contacto@cfclatam.cl
            </p>
          </div>
        </div>

      </div>
    
    </div>
    
  );
}

export default App;
